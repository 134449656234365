/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useParams } from 'react-router-dom';
import { newsAPI } from 'stateManager/services';
import { dateFormater } from 'utils';
import { BreadCrumbs, CustomSlider, HelmetHandle, Loading } from 'components/universal_components';
import PageFooter from 'components/universal_components/PageFooter/PageFooter';
import Linkify from 'linkify-react';
import styles from './SingleNewsPage.module.scss';
import SingleNewsSliderItem from './SingleNewsSliderItem/SingleNewsSliderItem';
import LastNewsSection from './LastNewsSection/LastNewsSection';

const SingleNewsPage = () => {
  const params = useParams();

  const { data: news, isFetching } = newsAPI.useFetchSingleNewsQuery(params.slug);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!news) {
    return <>not found</>;
  }

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Компания',
      link: '/about',
    },
    {
      title: 'Новости',
      link: '/news',
    },
    {
      title: news && `${news.title}`,
      link: `/news/${news.slug}`,
    },
  ];

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
  };

  const images = [{ id: 0, image: news.image }, ...(news.additional_images ? news.additional_images : [])];

  return (
    <div itemScope itemType="https://schema.org/NewsArticle" className={styles.wrapper}>
      <HelmetHandle
        title={news.title}
        metaTitle={news.meta_title}
        metaDescription={news.meta_description}
        description={news.short_description}
        image={news.cropped_image}
      />

      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1 itemProp="name" className={styles.title}>
          {news.title}
        </h1>

        <div className={styles.subInfo}>
          <p className={styles.date}>{`${dateFormater(news.date).slice(0, -3)}г.`}</p>
          <p className={styles.time_to_read}>
            Время на прочтение: ~{`${Math.round(news.reading_time / 60) ? Math.round(news.reading_time / 60) : 1}`} мин.
          </p>
        </div>

        <article className={styles.article}>
          <div className={styles.slider}>
            <CustomSlider data={images} SliderItem={SingleNewsSliderItem} settings={settings} variant="black" />
          </div>

          {/* <p itemProp="description" className={styles.description}>
            {news.full_description}
          </p> */}
          <p itemProp="description" className={styles.description}>
            <Linkify
              options={{
                target: '_blank',
                rel: 'noopener noreferrer',
              }}>
              {news.full_description}
            </Linkify>
          </p>
        </article>

        <div className={styles.lastNews}>
          <LastNewsSection currentNewsID={news.id} />
        </div>

        <PageFooter />
      </div>
    </div>
  );
};

export default SingleNewsPage;
